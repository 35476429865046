import PageFooter from '../componentParts/footer'
import TopNav from '../componentParts/topNav'
import Fade from 'react-reveal/Fade';
import { useRecoilValueLoadable } from 'recoil';
import { getSettings } from '../states/home';

function PrivacyPage() {
  const data = useRecoilValueLoadable(getSettings)
  const settings = data.state === "hasValue" ? data.contents : {}
  return (
    <div>
      <TopNav />
      <div className='documentPages' id='privacypage' >
        <div className="tou-jumbotron py-4 mb-4 pl-4 pb-2" style={{ backgroundImage: 'url(/grain.png)' }}>
          <Fade right>
            <div className="py-4 ml-4 pl-4 row">
              <h2>Privacy Policy</h2>
            </div>
            <div>
              <h4>WAYA MULTILINK TECHNOLOGY LTD</h4>

              <h5>Privacy Policy for Wayabank</h5>



              <h5>Effective date: December 22, 2020</h5>



              <h5>WAYA MULTILINK TECHNOLOGY LTD (“us”, “we”, or “our”) operates the Wayabank mobile application ( “Waya PayChat”).</h5>



              <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>



              <p>By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>



              <p>What This Privacy Policy Covers</p>



              <p>How we collect information from you.</p>


              <p>How we protect customers’ information.</p>


              <p>How we use the information, and share all personal information which you provide when you use our websites.</p>


              <p>General information in line with extant laws and regulations.</p>


              <p>The application of this policy is limited to persons over whom we have no direct control, employment or management.</p>



              <h6>Consent:</h6>
              <p>By using Wayabank, you have consented to our Privacy Policy and that you have the legal capacity to give consent. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS Wayabank FURTHER.</p>

              <h6>YourRight Not To Consent</h6>
              <p>You have the right not to consent to our use of your information. With many types of data, we’ve provided you with the ability to simply delete it if you don’t want us processing it anymore. For other types of data, we’ve given you the ability to stop the use of your data by disabling the feature altogether. You can do all these things in the app. If there are other types of information you don’t agree with us processing, you can inform us at info@wayapaychat.com.</p>



              <p>Information Collection</p>


              <p>Information We Collect</p>
              <p>Information we collect can be grouped under three categories:</p>



              <p>Information you provide us.</p>


              <p>Information we get when you use our services.</p>


              <p>Information we get from third parties, such as advertisers.</p>


              <h6>3.1.1 Information You Provide Us Voluntarily:</h6>

              <p>Personal Information. When you use our services, we collect personal information that you provide to us voluntarily. For example, most of our services require you to set up a Wayabank account, so we need to collect a few important details about you, such as your name, username, password, phone number, email address, phone number, birthday, age, gender, date of birth.</p>


              <p>You may also provide us whatever information you send through our WayaGram services. Users who view your content on our app may deal with the content in any form, such as saving or copying. In this case, it is thereby advised that you do not send messages or share content that you would not want others to share or save.</p>


              <p>Again, if you contact customer support or communicate with us in any other way, any information you share may be collected.</p>


              <h6>3.1.2 Information We Get From You When Using Our Services.</h6>

              <p>Generally, when you use our services, we collect information about the services you use and how you use them.</p>



              <p>Below, we explain, in details, general types of information we collect when you use our services:</p>



              <p>Device Information. We collect from you information about the devices you use. In this case, we collect: information about your hardware and software, such as hardware model, operating system version, device memory, ad IDs, unique app IDs, Bluetooth ID; installed apps; smartphones type; device IDs; mobile phone number;  unique device, browser type, language, battery level and time zone;</p>


              {/* <p>Contact List or Phonebook. Because Wayabank is also about communicating with other users alongside making payments, we may — with your permission — collect information from your device’s contact list or phonebook. This is solely to enable us to synchronize the contact information with your account on our platform so that you may transact, send memorable messages or interact with your friends, family members and loved ones on the Wayabank app.  Aside from the uses stated above, the permission is not used for any other purpose, and as a result, Wayabank does not access your calls, messages, or communication history.</p> */}


              <p>Payment and/or Bank Details (such as Bank Verification Number): We may also collect information relating to your bank verification number (BVN). Wayabank only runs a screen on this number and thereafter sends it to a Central Bank of Nigeria-licensed Thirty Party payment service provider in order to verify that the BVN is correct. Please note that this is totally optional, and where you choose to link up your BVN, we will take all reasonable steps to comply with all extant Central Bank of Nigeria laws, regulations, circulars or guidelines regarding the use and disposal of information relating to BVN, including that the BVN will not be used for any other purposes other than that stated here.</p>


              <p>Content information. While using WayaGram, a service on Wayabank, we collect the content you create on our services, information from the polls you create and run through WayaGram as well information about the content you create or provide, such as metadata provided with the content on WayaGram.</p>


              <p>Camera and Photos. Some of our services, especially on WayaGram and Pay Merchant, require us to collect images, videos and other information from your device’s camera, photos. For example, you won’t be able to send upload photos or videos from your camera or video roll unless we can access the camera, video or photos archives on your device. Again, you won’t be able to scan QR codes unless Wayabank accesses your camera. Any media uploaded in this fashion will be collected and stored on our servers. If you wish to change our access or permissions, you may simply decline the access; or do so through your device’s settings.</p>


              <p>Location Information. When you use our services we may collect information about your location, including name, place of residence, place of business, etc. With your permission, we may also collect information about your precise location using methods that include GPS, wireless networks, cell towers, Wi-Fi access points, and other sensors, such as gyroscopes, accelerometers, and compasses.</p>


              <p>Push Notifications. We may request to send you push notifications regarding your account or the App. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.</p>


              <h6>Data From Posts And Surveys</h6>
              <p>We collect personal and other information you may provide when responding to polls or surveys created on the Wayabank app.</p>



              <p>Information Collected Automatically by Cookies and Other Tracking Technologies. Like most online services and mobile applications, we may use cookies and other technologies, such as web beacons, web storage, and unique advertising identifiers, to collect information about your activity, browser, and device. We may also use these technologies to collect information when you interact with services we offer through one of our partners, such as advertising and commerce features. For example, we may use information collected on other websites to show you more relevant ads. Most web browsers are set to accept cookies by default. If you prefer, you can usually remove or reject browser cookies through the settings on your browser or device. Keep in mind, though, that removing or rejecting cookies could affect the availability and functionality of our services.</p>
              <p>Information from device sensors, such as compasses, microphones, and whether you have headphones connected, accelerometers, gyroscopes; vending machines; and</p>


              <p>Information about your wireless and mobile network connections, such as mobile phone number, service provider, IP address, and signal strength.</p>


              <p>Codes, passwords or usernames shared with the Wayabank app when using services such as Transfer, Fund Wallet, Pay Merchant, Receive Money, Retrieve Money, and Payment Requests; or while using its Merchant channels.</p>


              <p>Information as to payment methods; credits;  total payment amount; time of payment; the location of the merchant (or POS); QR codes; Point-of-Sale terminal.</p>


              <p>Information relating to any mobile  marketing campaign launched by Wayabank.</p>


              <p>Personal information relating to place of residence, nationality, date of birth and e-mail address.</p>


              <p>Log Information. We also collect log information when you use our website, such as: Details about how you’ve used our services; device information, such as your web browser type and language;  access times; pages viewed; IP address; identifiers associated with cookies or other technologies that may uniquely identify your device or browser; and pages you visited before or after navigating to our website.</p>


              <p>We also collect information about way you interact with our services, such as the photos you view or apply to your feeds or the content you view on WayaGram.</p>


              <p>We also collect information on how you communicate with other users such as their names, the time and date of your communications; the number of messages you exchange with other users; the users with whom you exchange the most messages and your interactions with messages (like when you open a message or take a screenshot).</p>


              <p>We collect information relating to the saving or activation of customer loyalty cards and services for marketing purposes.</p>


              <p>We also collect information relating to the  Google Firebase Software Development Kit (SDK) of Google Inc. (“Google”) in the Wayabank app, in particular, information  on the analytics ID (random value on the basis of which Wayabank can identify the customer); the client ID (random value which identifies the used device and allows Google to summarize sent events in a device session) which, however, does not allow inferences to be made about the user’s device key device details (brand, type, screen, memory) information on the platform (e.g. iOS and Android version); the version of the installed Wayabank app; the type and version of the used Internet browser, where applicable</p>


              <p>Information from other services on Wayabank products, may require you to provide us with a debit or credit card number and its associated account information. We collect this information too.</p>


              <h6>3.1.3 Information We Collect from Third Parties</h6>

              <p>We may collect information about you from other users, our affiliates and third parties. The following are some instances:  If you link your Wayabank account to another service (such as a third-party application), we may receive information from the other service, such as how you use that service; advertisers, app developers, publishers, and other third parties may also share information with us. We may use this information, among other things, to help target or measure the performance of advertisements. If another user uploads their contact list, we may combine information from that user’s contact list with other information that we have collected about you.</p>



              <h6>Use of Information</h6>
              <p>The information we obtain from you is used to provide the services you request. However, to the extent that we use your personal information to market to you, we will give you the opportunity to opt out of such uses. Accordingly, note generally that we use the information we collect from you to provide you with a range of products and services that we relentlessly improve upon. Under-listed are what we specifically use your information for. We use your information to:</p>



              <p>Develop, operate, improve, deliver, maintain, and protect our products and services.</p>


              <p>Enable us process your payment requests such as when you are transferring funds; using our Fund Wallet; paying Merchants; receiving money; retrieving payment; or making other types of payment requests.</p>


              <p>Send you communications and notifications, including by email. For example, we may use email to respond to support inquiries or to share information about our products, services, and promotional offers that we think may interest you.</p>


              <p>Monitor and analyze trends and usage.</p>


              <p>Personalize our services by, among other things, customizing the content we show you, including ads.</p>


              <p>Contextualize your experience by, among other things, tagging your content using your precise location information (if, of course, you’ve given us permission to collect that information) and applying other labels based on the content.</p>


              <p>Provide and improve our advertising services, ad targeting, and ad measurement, including through the use of your precise location information (again, if you’ve given us permission to collect that information), both on and off our services.</p>


              <p>Enhance the safety and security of our products and services.</p>


              <p>Verify your identity and prevent fraud or other unauthorized or illegal activity.</p>


              <p>Use information we’ve collected from cookies and other technology to enhance our services and your experience with them.</p>


              <p>Enforce our Terms of Service and other usage policies and comply with legal requirements.</p>


              <h6>5. Sharing of the information you provide us</h6>
              <p>We may share information about you in the following ways:</p>

              <p>We may share the following information with merchants, our business partners and with other Wayabank users :</p>



              <p>Information about you, such as your username, name.</p>


             <p>With our business partners, we may share information about your payment requests and other payment services you use while on Wayabank, but at all times assuring you that we can only share such information with registered or licensed and trusted partners.</p>


              <p>Information about how you have interacted with our services, the names of other users you interact with, such as information that will help other users understand your connections with others using our services.</p>


              <p>Content you post or send. How widely your content is shared depends on your personal settings and the type of service you are using.</p>


              <p>Any additional information you have directed us to share. For example, Wayabank  will share your information when you connect your account to a third-party app, and if you share information or content from Wayabank  to the third-party app.</p>


              <p>We may further share the following information with all users as well as with our business partners and the general public:</p>



              <p>Public information like your name, username, and profile pictures.</p>


              <p>Content submissions that are set to be visible to everyone, and any content you submit to a public service inherently. This content may be viewed and shared by the general public both on and off our services, including through search results, on websites, in applications and in online and offline broadcasts.</p>


              <p>With our affiliates. We may share information with entities of the Waya Multi Link Limited group of companies.</p>


              <p>With third parties. We may share information about you with service providers who provide services on our behalf, including to measure and optimize ad performance and deliver more relevant ads, including on third-party websites and applications.</p>


              <p>We may share information about you with business partners who provide services and features on our services.</p>


              <p>We may share information about you, such as device and usage information, to help us and other people prevent fraud.</p>


              <p>We may share information about you for legal, safety and security reasons.</p>


              <p>We may share information about you if we reasonably believe that disclosure of the information is necessary to: comply with any legal process, government request or applicable law, rule or regulation.</p>


              <p>Investigate, remedy or enforce potential violations of the Terms of Service.</p>


              <p>Protect the rights, property, or safety of us, our users, or others.</p>


              <p>Detect and resolve any fraud or security problem.</p>


              <p>We may share information about you in connection with a merger or acquisition. If Waya Multlink Technology Ltd is involved in a merger, sale of assets, financing, liquidation or bankruptcy, or the acquisition of all or part of our business from another company, we may share your information with that company before and after the closing of the transaction.</p>


              <p>We may also share aggregated, non-personally identifiable or anonymized information with third parties who provide services to us or conduct business activities for us.</p>


              <h6>Governing Principles of Data Processing</h6>
             <p> All information we collect from you on Wayabank shall be collected and processed in accordance with legitimate and lawful purpose consented to by you herein, provided that a further processing may be done only for archiving, scientific research, historical research or statistical purposes for public interest in line with extant regulations and laws. Personal data shall be adequate, accurate and without prejudice to the dignity of human person: Personal data shall be stored only for the period within which it is reasonably needed, and shall be secured against all foreseeable hazards and breaches such as theft, cyberattack, viral attack, dissemination, manipulations of any kind, damage by rain, fire or exposure to other natural elements. Our data collection shall depict that we owe a duty of care to you. We shall be accountable in line with extant laws and regulations.</p>



              <h6>Transfer Of Data</h6>
              <p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction. If you are located outside Nigeria and choose to provide information to us, please note that we transfer the data, including Personal Data, to Nigeria and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer. Waya Multilink Technology Ltd will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>



              <h6>FurtherSecurity Precautions</h6>
              <p>Our site has strict security measures in place to protect the loss, misuse and alteration of information under our control. Each time you modify or access your account information, we offer you the use of a secure server. Once your information is in our possession, we adhere to strict security guidelines, protecting it from unauthorized access. However, please keep in mind that we cannot guarantee that the Internet itself is 100% secure and therefore cannot take responsibility for illegal acts of hackers as long as we have taken the necessary precautions. While we do our best to protect your personal information, the transmission of personal information to and from our services is at your own risk. You should only access our site or our services through and in a secure environment. Most importantly, you are responsible for keeping your username and password secure, and you should not disclose them to anyone.</p>



              <h6>Analyticsand Tracking Technologies</h6>
             <p>We may also partner with selected third-party vendors, such as Google Analytics, to allow tracking technologies and remarketing services on the App through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the App, determine the popularity of certain content, and better understand online activity. By accessing the App or our Site, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, if you do not want any information to be collected and used by tracking technologies, you can visit the third-party vendor. For example, you may opt out of certain Google Analytics features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy. For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page.</p>

              <p>You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.</p>

              <h6>Personal Data Retention Period</h6>
              <p>Wayabank will only retain your personal information for as long as necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting, legal or other requirements). Upon request to close an account, your account will be closed but this closure will not imply the deletion of the historical records of the account for the reasons already indicated. However, unless required by law or law enforcement officials and / or regulators, further processing of account-related personal information will cease upon termination.</p>



              <h6>Your Privacy Rights</h6>
              <p>In certain circumstances, as provided by the Data Protection Regulations of Nigeria or other applicable law, you may object to the processing of your personal information. To make such a request, please use the contact details provided below. We will review and act on any request in accordance with applicable data protection laws.</p>



              <h6>Links to Other Sites</h6>
              <p>Our site is linked to other websites that may collect personally identifiable information about you. Wayabank is not responsible for the privacy practices or the content of such linked websites.</p>



              <h6>Choice/Opt-Out</h6>
              <p>Wayabank offers all users the ability to opt out of receiving non-essential communications (promotional, marketing-related) from us on behalf of our partners, and from us in general, after creating an account. If you would like to remove your contact information from all Wayabank lists and newsletters, please visit https://www.wayapaychat.com or contact us through info@wayapaychat.com. In the future, you will also be able to unsubscribe by clicking the one-click unsubscribe button at the bottom of each email.</p>



              <h6>Third PartyService Providers</h6>
              <p>We may employ third party companies and individuals to facilitate our service (“service providers”), to provide the service on our behalf, to perform service related services, or to help us analyze how our service is used. These third parties only have access to your personal data to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>



              <h6>Remedies, Questions and Contact</h6>
              <p>If you observe or have good reasons to believe that your rights to privacy have been violated or may be violated by the use of our site, please submit your complaints or questions regarding our policy to the following address: info@wayapaychat.com.  Our data protection officer will attend to this. Generally, you can contact us with any problem by mail to info@wayapaychat.com.</p>



              <h6>Age Restrictions:</h6>
              <p>In order to use Wayabank, you must be of legal age or older in Nigeria.  If you are under the legal age, you must have your parent or legal guardian’s permission to use Wayabank. If we learn that we have collected or received personal information from you while being under the legal age without verification of parental consent, we will delete that information. If you believe we might have any information from or about a person under the legal age stated above please contact us at info@wayapaychat.com.</p>

              <p>You must also not access Wayabank or accept these Terms if you are a person who is either barred or otherwise legally prohibited from receiving or using the Service or any Products of Wayabank under the laws of Nigeria.</p>



              <h6>17. Notification of Changes</h6>
              <p>Wayabank reserves the right to make changes and/or updates to this privacy policy from time to time without notice, and the latest version of this policy will be posted on our websites. You are advised to periodically review this privacy policy for any changes. Changes to this privacy policy are effective when posted on our web pages.</p>
            </div>
          </Fade>
        </div>
        <article>
          <div className="entry-content" dangerouslySetInnerHTML={{ __html: settings.privacy_and_policy }} />
        </article>
        <PageFooter />
      </div>
    </div>
  )
}

export default PrivacyPage