import React, { useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import validator from 'validator';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Button from '../Button';
import moment from 'moment';

const CACForm2 = (props) => {
  const { setPage, setData, data,dateError, handleDateChange} = props;
  const [loading, setLoading] = useState(false);
  return (
    <form>
      <div className='inputbox-with-one-input'>
        <input
          placeholder='Surname'
          type='text'
          value = {data?.surname}
          onChange={(e) => {
            setData({ ...data, surname: e.target.value });
          }}
        />
      </div>

      <div className='inputbox-with-one-input'>
        <input
          placeholder='First name'
          type='text'
          value = {data?.firstName}
          onChange={(e) => {
            setData({ ...data, firstName: e.target.value });
          }}
        />
      </div>

      <div className='inputbox-with-one-input'>
        <input
          placeholder='Personal Email'
          type='email'
          value = {data?.email}
          onChange={(e) => {
            setData({ ...data, email: e.target.value });
          }}
        />
      </div>

          <div className='inputbox-with-one-input-log'>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">Tel</span>
                    </div>
                  <input
                    value={data?.phoneNumber}
                    className="form-control"
                    length='13'
                    maxLength={13}
                    onChange={(e)=>{                      
                      const onlyDigits = e.target.value.replace(/\D/g, "");
                      setData({ ...data, phoneNumber: onlyDigits })
                    }}  />
                </div>
                </div>
      <div className='inputbox-with-one-input'>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon1">DOB</span>
        </div>
        <input
          className="form-control"
          placeholder='Date of Birth'
          type='date'
          value = {data?.dateOfBirth}
          onMouseOut={handleDateChange}
          onChange={(e) => {
            setData({ ...data, dateOfBirth: moment(e.target.value).format('YYYY-MM-DD') });
          }}
        />
        </div>
        <span className="text-danger">{dateError ?? ''}</span>
      </div>
      <div className='inputbox-with-one-input'>
        <select
        value = {data?.gender}
         onChange={(e) => {
          setData({ ...data, gender: e.target.value });
        }}
        > <option>Select Gender</option>
          <option value='MALE'>Male</option>
          <option value={'FEMALE'}>Female</option>
        </select>
        
      </div>

      {/* <div className='inputbox-with-one-input'>
        <input
          placeholder='State'
          type='text'
          value = {data?.state}
          onChange={(e) => {
            setData({ ...data, state: e.target.value });
          }}
        />
      </div>
      

      <div className='inputbox-with-one-input'>
        <input
          placeholder='City'
          type='text'
          value = {data?.city}
          onChange={(e) => {
            setData({ ...data, city: e.target.value });
          }}
        />
      </div>

      {/* <div className='inputbox-with-one-input'>
        <input
          placeholder='Office Address'
          type='text'
          value = {data?.officeAddress}
          onChange={(e) => {
            setData({ ...data, officeAddress: e.target.value });
          }}
        />
      </div> */}

      <div className='submit-modal-btn-wrap'>
        <Button
          type='button'
          loading={loading}
          content='Next'
          onClick={() => {
            setLoading(true);
            // console.log(data);
            if (validator.isEmpty(data.surname)) {
              swal('Oops!', 'Surname cannot be empty', 'error');
              setLoading(false);
              return;
            }
            if (validator.isEmpty(data.firstName)) {
              swal('Oops!', 'First name cannot be empty', 'error');
              setLoading(false);
              return;
            }
            if (validator.isEmpty(data.email)) {
              swal('Oops!', 'Email cannot be empty', 'error');
              setLoading(false);
              return;
            }
            if (!validator.isEmail(data.email)) {
              swal('Oops!', 'Invalid Email Format', 'error');
              setLoading(false);
              return;
            }
            if (validator.isEmpty(data.state)) {
              swal('Oops!', 'State cannot be empty', 'error');
              setLoading(false);
              return;
            }
            if (validator.isEmpty(data.city)) {
              swal('Oops!', 'City cannot be empty', 'error');
              setLoading(false);
              return;
            }
            if (validator.isEmpty(data.gender)) {
              swal('Oops!', 'Gender cannot be empty', 'error');
              setLoading(false);
              return;
            }
            if (validator.isEmpty(data.officeAddress)) {
              swal('Oops!', 'Office Address cannot be empty', 'error');
              setLoading(false);
              return;
            }
            if (dateError === 'Invalid date') {
              swal('Oops!', 'invalid date of birth', 'error');
              setLoading(false);
              return;
            }
            setPage(3);
          }}
        />
      </div>
      <div>
        <Button
          type='button'
          content='Back'
          onClick={() => {
            setPage(1);
          }}
        />
      </div>
    </form>
  );
};

CACForm2.propTypes = {
  setPage: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  data: PropTypes.shape.isRequired,
};

export default CACForm2;
