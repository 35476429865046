import PageFooter from '../componentParts/footer'
import TopNav from '../componentParts/topNav'
import Fade from 'react-reveal/Fade';
import { getSettings } from '../states/home';
import { useRecoilValueLoadable } from 'recoil';

function TermsOfUse() {
  const data = useRecoilValueLoadable(getSettings)
  const settings = data.state === "hasValue" ? data.contents : {}
  return (
    <div>
      <TopNav />
      <div id='termsofservice' className='documentPages'>
        <div className="tou-jumbotron py-4 mb-4 pl-4 pb-2" style={{ backgroundImage: 'url(/grain.png)' }}>
          <Fade right>
            <div className="py-4 ml-4 pl-4 row">
              <h2>Terms of Use</h2>
            </div>
            <div>
              <div>
                <h4>General</h4>


                <h6>1.1. Service/scope</h6>

                <p>WAYA MULTI LINK TECHNOLOGY (hereinafter referred to as ‘Waya PayChat’) is a Nigerian limited liability company headquartered in Lagos,  Nigeria.</p>



                <p>Wayabank offers private customers (hereinafter referred to as ‘customers’) a mobile payment application for the iOS and Android operating systems (hereinafter referred to as the “Waya PayChat app”) and Web based application.</p>


                <p>The Wayabank app can be used by customers as a means of payment in the area of stationary retail, at vending machines, in online shops and in apps and can thus be used with all merchants or service providers that accept Wayabank as a payment method (hereinafter referred to as ‘merchants’). Wayabank  also offers value-added services. These include, in particular, the saving or activation of customer loyalty cards and services in the area of mobile marketing. These value-added services allow customers to receive and manage coupons, stamp cards and other campaigns in the Waya  PayChat app. Customers can likewise use these services to collect stamps and redeem loyalty rewards, discounts and credits via the Wayabank app.</p>



                <p>All personal designations apply to both genders equally.</p>

                <p>These General Terms and Conditions (hereinafter referred to as the ‘GTCs’) govern the use of all services offered in the Waya PayaChat app. These services include all payment functions and value-added services, which are described on the website www.waya paychatpaychat.com and in the Wayabank app itself (hereinafter referred to as ‘services’). These GTCs shall be deemed to have been accepted as soon as customers register via the Wayabank app and confirm with the click of the mouse that they have read and understood the GTCs.</p>



                <h6>1.2. Access to the Wayabank  services</h6>

                <p>All Wayabank services such as WayaGram; Fund Wallet, Pay Merchant; Transfer; Receive Money; Retrieve Payment, and other payment services can be used on all smartphones and general SMS/text message capable phones available on the Nigerian market, irrespective of the manufacturer, on which the Wayabank app can be installed. For the Wayabank app to be installed on a smartphone, it must be equipped with the iOS or Android operating system, support BLE (Bluetooth low energy) and have correctly implemented the Bluetooth protocol. The minimum required iOS or Android version can be found at www.wayapaychatpaychat.com or in the relevant app store.</p>



                <p>Customers with a smartphone registered in their name on which the Wayabank app has been installed are granted access to the services.</p>

                <p>Technical access to the services is obtained via the Internet connection of the customer’s smartphone, which acts as a personal terminal, and a dedicated infrastructure made available by a business customer (e.g. Beacons – transmitters based on the BLE wireless standard). Certain services cannot be used in the absence of an Internet connection.</p>

                <p>Payment and additional functions can be restricted by Wayabank when used outside Nigeria on the basis of regulatory requirements.</p>



                <h6>1.3. Information  Collection</h6>

                <p>Information we collect can be grouped under three categories:</p>



                <p>Information you provide us.</p>


                <p>Information we get when you use our services.</p>


                <p>Information we get from third parties, such as advertisers.</p>


                <h6>1.3.1 Information You Provide Us Voluntarily:</h6>

                <p>Personal Information. When you use our services, we collect personal information that you provide to us voluntarily. For example, most of our services require you to set up a Wayabank account, so we need to collect a few important details about you, such as your name, username, password, phone number, email address, phone number, birthday, age, gender, date of birth.</p>


                <p>You may also provide us whatever information you send through our WayaGram services. Users who view your content on our app may deal with the content in any form, such as saving or copying. In this case, it is thereby advised that you do not send messages or share content that you would not want others to share or save.</p>


                <p>Again, if you contact customer support or communicate with us in any other way, any information you share may be collected.</p>


                <h6>1.3.2 Information We Get From You When Using Our Services.</h6>

                <p>Generally, when you use our services, we collect information about the services you use and how you use them.</p>



                <p>Below, we explain, in details, general types of information we collect when you use our services:</p>



                <p>Device Information. We collect from you information about the devices you use. In this case, we collect: information about your hardware and software, such as hardware model, operating system version, device memory, ad IDs, unique app IDs, Bluetooth ID; installed apps; smartphones type; device IDs; mobile phone number;  unique device, browser type, language, battery level and time zone;</p>


                {/* <p>Contact List or Phonebook. Because Wayabank is also about communicating with other users alongside making payments, we may — with your permission — collect information from your device’s contact list or phonebook. This is solely to enable us to synchronize the contact information with your account on our platform so that you may transact, send memorable messages or interact with your friends, family members and loved ones on the Wayabank app.  Aside from the uses stated above, the permission is not used for any other purpose, and as a result, Wayabank does not access your calls, messages, or communication history.</p> */}


                <p>Payment and/or Bank Details (such as Bank Verification Number): We may also collect information relating to your bank verification number (BVN). Wayabank only runs a screen on this number and thereafter sends it to a Central Bank of Nigeria-licensed Thirty Party payment service provider in order to verify that the BVN is correct. Please note that this is totally optional, and where you choose to link up your BVN, we will take all reasonable steps to comply with all extant Central Bank of Nigeria laws, regulations, circulars or guidelines regarding the use and disposal of information relating to BVN, including that the BVN will not be used for any other purposes other than that stated here.</p>


                <p>Content information. While using WayaGram, a service on Wayabank, we collect the content you create on our services, information from the polls you create and run through WayaGram as well information about the content you create or provide, such as metadata provided with the content on WayaGram.</p>


                <p>Camera and Photos. Some of our services, especially on WayaGram and Pay Merchant, require us to collect images, videos and other information from your device’s camera, photos. For example, you won’t be able to send upload photos or videos from your camera or video roll unless we can access the camera, video or photos archives on your device. Again, you won’t be able to scan QR codes unless Wayabank accesses your camera. Any media uploaded in this fashion will be collected and stored on our servers. If you wish to change our access or permissions, you may simply decline the access; or do so through your device’s settings.</p>


                <p>Location Information. When you use our services we may collect information about your location, including name, place of residence, place of business, etc. With your permission, we may also collect information about your precise location using methods that include GPS, wireless networks, cell towers, Wi-Fi access points, and other sensors, such as gyroscopes, accelerometers, and compasses.</p>


                <p>Push Notifications. We may request to send you push notifications regarding your account or the App. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.</p>


                <p>Data From Posts And Surveys</p>
                <p>We collect personal and other information you may provide when responding to polls or surveys created on the Wayabank app.</p>



                <p>Information Collected Automatically by Cookies and Other Tracking Technologies. Like most online services and mobile applications, we may use cookies and other technologies, such as web beacons, web storage, and unique advertising identifiers, to collect information about your activity, browser, and device. We may also use these technologies to collect information when you interact with services we offer through one of our partners, such as advertising and commerce features. For example, we may use information collected on other websites to show you more relevant ads. Most web browsers are set to accept cookies by default. If you prefer, you can usually remove or reject browser cookies through the settings on your browser or device. Keep in mind, though, that removing or rejecting cookies could affect the availability and functionality of our services.</p>
                <p>Information from device sensors, such as compasses, microphones, and whether you have headphones connected, accelerometers, gyroscopes; vending machines; and</p>


                <p>Information about your wireless and mobile network connections, such as mobile phone number, service provider, IP address, and signal strength.</p>


                <p>Codes, passwords or usernames shared with the Wayabank app when using services such as Transfer, Fund Wallet, Pay Merchant, Receive Money, Retrieve Money, and Payment Requests; or while using its Merchant channels.</p>


                <p>Information as to payment methods; credits;  total payment amount; time of payment; the location of the merchant (or POS); QR codes; Point-of-Sale terminal.</p>


                <p>Information relating to any mobile  marketing campaign launched by Wayabank.</p>


                <p>Personal information relating to place of residence, nationality, date of birth and e-mail address.</p>


                <p>Log Information. We also collect log information when you use our website, such as: Details about how you’ve used our services; device information, such as your web browser type and language;  access times; pages viewed; IP address; identifiers associated with cookies or other technologies that may uniquely identify your device or browser; and pages you visited before or after navigating to our website.</p>


                <p>We also collect information about way you interact with our services, such as the photos you view or apply to your feeds or the content you view on WayaGram.</p>


                <p>We also collect information on how you communicate with other users such as their names, the time and date of your communications; the number of messages you exchange with other users; the users with whom you exchange the most messages and your interactions with messages (like when you open a message or take a screenshot).</p>


                <p>We collect information relating to the saving or activation of customer loyalty cards and services for marketing purposes.</p>


                <p>We also collect information relating to the  Google Firebase Software Development Kit (SDK) of Google Inc. (“Google”) in the Wayabank app, in particular, information  on the analytics ID (random value on the basis of which Wayabank can identify the customer); the client ID (random value which identifies the used device and allows Google to summarize sent events in a device session) which, however, does not allow inferences to be made about the user’s device key device details (brand, type, screen, memory) information on the platform (e.g. iOS and Android version); the version of the installed Wayabank app; the type and version of the used Internet browser, where applicable</p>


                <p>Information from other services on Wayabank products, may require you to provide us with a debit or credit card number and its associated account information. We collect this information too.</p>


                <h6>1.3.3 Information We Collect from Third Parties</h6>

                <p>We may collect information about you from other users, our affiliates and third parties. The following are some instances:  If you link your Wayabank account to another service (such as a third-party application), we may receive information from the other service, such as how you use that service; advertisers, app developers, publishers, and other third parties may also share information with us. We may use this information, among other things, to help target or measure the performance of advertisements. If another user uploads their contact list, we may combine information from that user’s contact list with other information that we have collected about you.</p>



                <h6>1.3.4. Use of Information</h6>

                <p>The information we obtain from you is used to provide the services you request. However, to the extent that we use your personal information to market to you, we will give you the opportunity to opt out of such uses. Accordingly, note generally that we use the information we collect from you to provide you with a range of products and services that we relentlessly improve upon. Under-listed are what we specifically use your information for. We use your information to:</p>



                <p>Develop, operate, improve, deliver, maintain, and protect our products and services.</p>


                <p>Enable us process your payment requests such as when you are transferring funds; using our Fund Wallet; paying Merchants; receiving money; retrieving payment; or making other types of payment requests.</p>


                <p>Send you communications and notifications, including by email. For example, we may use email to respond to support inquiries or to share information about our products, services, and promotional offers that we think may interest you.</p>


                <p>Monitor and analyze trends and usage.</p>


                <p>Personalize our services by, among other things, customizing the content we show you, including ads.</p>


                <p>Contextualize your experience by, among other things, tagging your content using your precise location information (if, of course, you’ve given us permission to collect that information) and applying other labels based on the content.</p>


                <p>Provide and improve our advertising services, ad targeting, and ad measurement, including through the use of your precise location information (again, if you’ve given us permission to collect that information), both on and off our services.</p>


                <p>Enhance the safety and security of our products and services.</p>


                <p>Verify your identity and prevent fraud or other unauthorized or illegal activity.</p>


                <p>Use information we’ve collected from cookies and other technology to enhance our services and your experience with them.</p>


                <p>Enforce our Terms of Service and other usage policies and comply with legal requirements.</p>


                <h6>1.3.5 Sharing of the information you provide us</h6>

                <p>We may share information about you in the following ways:</p>

                <p>We may share the following information with merchants, our business partners and with other Wayabank users :</p>



                <p>Information about you, such as your username, name.</p>


                <p>With our business partners, we may share information about your payment requests and other payment services you use while on Wayabank, but at all times assuring you that we can only share such information with registered or licensed and trusted partners.</p>


                <p>Information about how you have interacted with our services, the names of other users you interact with, such as information that will help other users understand your connections with others using our services.</p>


                <p>Content you post or send. How widely your content is shared depends on your personal settings and the type of service you are using.</p>


                <p>Any additional information you have directed us to share. For example, Wayabank  will share your information when you connect your account to a third-party app, and if you share information or content from Wayabank  to the third-party app.</p>


                <p>We may further share the following information with all users as well as with our business partners and the general public:</p>



                <p>Public information like your name, username, and profile pictures.</p>


                <p>Content submissions that are set to be visible to everyone, and any content you submit to a public service inherently. This content may be viewed and shared by the general public both on and off our services, including through search results, on websites, in applications and in online and offline broadcasts.</p>


                <p>With our affiliates. We may share information with entities of the Waya Multi Link Limited group of companies.</p>


                <p>With third parties. We may share information about you with service providers who provide services on our behalf, including to measure and optimize ad performance and deliver more relevant ads, including on third-party websites and applications.</p>


                <p>We may share information about you with business partners who provide services and features on our services.</p>


                <p>We may share information about you, such as device and usage information, to help us and other people prevent fraud.</p>


                <p>We may share information about you for legal, safety and security reasons.</p>


                <p>We may share information about you if we reasonably believe that disclosure of the information is necessary to: comply with any legal process, government request or applicable law, rule or regulation.</p>


                <p>Investigate, remedy or enforce potential violations of the Terms of Service.</p>


                <p>Protect the rights, property, or safety of us, our users, or others.</p>


                <p>Detect and resolve any fraud or security problem.</p>


                <p>We may share information about you in connection with a merger or acquisition. If Waya Multlink Technology Ltd is involved in a merger, sale of assets, financing, liquidation or bankruptcy, or the acquisition of all or part of our business from another company, we may share your information with that company before and after the closing of the transaction.</p>


                <p>We may also share aggregated, non-personally identifiable or anonymized information with third parties who provide services to us or conduct business activities for us.</p>


                <h6>1.4. Confidentiality and Security Precautions</h6>

                <p>Wayabank is bound by statutory confidentiality requirements. Customers shall accept that the nature of the business relationship and their master data (e.g. name, place of residence) may, where necessary, be disclosed to the payment recipient and other third parties in order to facilitate the provision of services.</p>

                <p>Customers shall receive assurance that data relating to the content of business relationships (e.g. account and payment data) is in principle deemed confidential.  Our site also has strict security measures in place to protect the loss, misuse and alteration of information under our control. Each time you modify or access your account information, we offer you the use of a secure server. Once your information is in our possession, we adhere to strict security guidelines, protecting it from unauthorized access. However, please keep in mind that we cannot guarantee that the Internet itself is 100% secure and therefore cannot take responsibility for illegal acts of hackers as long as we have taken the necessary precautions. While we do our best to protect your personal information, the transmission of personal information to and from our services is at your own risk. You should only access our site or our services through and in a secure environment. Most importantly, you are responsible for keeping your username and password secure, and you should not disclose them to anyone.</p>



                <h6>1.5. Support Wayabank</h6>

                <p>Wayabank shall provide customers with a help function via the Wayabank app that offers technical support. Wayabank can also call on third parties for the provision of this support. To enable them to perform this task, they may be granted access to relevant data.</p>



                <h6> 1.6. Customer’s duties of care</h6>

                <p>When using the Wayabank app, the following duties of care, in particular, must be observed:</p>

                <p>Customers must protect their Wayabank account against unauthorized use or manipulation (e.g. by locking the device or display).</p>



                <p>The code for the use of the Wayabank app, especially for the topping up and releasing of credit and the confirmation of payments above a certain amount, as well as the codes for the device and display locking functions, must be kept secret and must not be disclosed to other individuals under any circumstances or stored together with the smartphone.</p>



                <p>The selected code may not be made up of easily ascertainable combinations (mobile number, date of birth, etc.).</p>



                <p>Should damages be suffered, customers must, to the best of their knowledge, contribute to clarifying the case in question and mitigating the damage. In the event of criminal acts, customers must file a complaint to the relevant statutory authority.</p>



                <p>Upon installing the Wayabank app on their smartphone, customers shall confirm that they are the legitimate and authorized user of the smartphone number. Customers shall be deemed responsible for the use of their smartphone. They shall bear all consequences that arise from the use of the Wayabank app on their smartphone.</p>



                <p>Should there be reason to assume that unauthorized individuals have gained access to the codes for the device and display locking functions, these must be changed immediately.</p>



                <p>If the smartphone is lost, and especially in the case of theft, Wayabank must be informed promptly so that the Wayabank app can be blocked.</p>



                <p>Jail breaking (the deactivation of the smartphone’s security structures for the installation of applications that are not officially available) and the setting up of root access (establishment of access at the system level of the smartphone) are forbidden, as is the installation of unauthorized apps, as these make the smartphone more prone to viruses and malware.</p>



                <p>Customers shall be required to check executed payments. Should Customers identify discrepancies, they must immediately inform Wayabank by phone and provide written notification no later than thirty (30) days (postmark date) after the payment date. If customers are requested to submit a damage notification/complaint form, this must be completed and signed within ten (10) days (postmark date) of the request being made and returned to Wayabank.</p>



                <h6>1.7. Misuse</h6>

                <p>If the use of the Wayabank app deviates significantly from normal usage patterns or if there is any indication of behavior that is illegal or in breach of the agreement, Wayabank can encourage customers to use the app or in-app messaging and social media solution in a legally and contractually compliant manner, alter the provision of the service with no compensation or prior notification, terminate the agreement without notice and with no compensation and, where necessary, demand compensation for damages and indemnification against third party claims. The same shall apply in instances in which customers provide incorrect or incomplete details upon concluding the agreement.

                </p>



                <h6>1.8. Liability</h6>

                <p>Should Wayabank breach the agreement, it shall assume liability for any demonstrable damages suffered by the customers in question unless it can prove that no fault can be attributed to it. Wayabank shall assume no liability whatsoever for slight negligence. Wayabank shall provide compensation for material damages and financial losses of up to a maximum of NGN 20,000.</p>



                <p>To the extent permitted by law, Wayabank shall not accept any liability for secondary damages, lost profits or data losses under any circumstances. Wayabank shall likewise assume no liability for damages or losses suffered as a result of use of the Wayabank app that is deemed illegal or in breach of the agreement.</p>



                <p>Technical access to the services shall be the responsibility of the customer. Wayabank shall assume no liability for the network operators (providers) and shall also not accept any liability, to the extent permitted by law, for the hardware and software required for the use of the services.</p>



                <p>Wayabank shall likewise, to the extent permitted by law, assume no liability for damages suffered by customers owing to transmission errors, force majeure, technical defects or faults (in particular due to outages suffered by Wayabank  or a lack of an Internet connection), illegal interference with telecommunication facilities and networks, network overloads, the deliberate blocking of electronic access by third parties, interruptions or other deficiencies.</p>



                <p>Wayabank shall endeavour to ensure the uninterrupted availability and fault-free usability of the Wayabank app. Wayabank cannot, however, guarantee this at all times. In particular, Wayabank shall reserve the right to interrupt access to the Wayabank app and/or the services offered in the app at any time should it identify heightened security risks or faults as well as for the purpose of performing maintenance work. Provided that Wayabank exercises the level of care and due diligence customary in the industry, customers shall bear any losses suffered due to interruptions of this kind.</p>



                <h6>1.9. Assumption of damages</h6>

                <p>Wayabank shall assume responsibility for damages pursuant to section 1.8 (liability) suffered by customers due to the unauthorized use of the Wayabank app by third parties, provided the customers in question can demonstrate that they adhered to these GTCs and that no fault can be attributed to them in any other way. Any damages must be reported to Wayabank immediately upon being identified. The damage notification form must be completed, signed and returned to Wayabank within ten (10) days of receipt.</p>



                <h6>1.10. Electronic communication</h6>

                <p>Communication between Wayabank and customers shall primarily take place via the Wayabank app or SMS/text message solution. Where necessary, Wayabank can also provide customers with notifications via e-mail provided they have saved a corresponding address. Customers shall be responsible for ensuring the accuracy of the provided address and shall also acknowledge that electronic communication is neither confidential nor secure. Such communication can be viewed, intercepted or amended by third parties or can be lost. If orders or instructions issued by customers via electronic channels are not explicitly confirmed by Wayabank, customers must assume that they have not been received by Wayabank. Wayabank shall assume no liability for damages arising in connection with notifications sent to Wayabank via ordinary e-mail or another electronic message transmission system.</p>



                <h6>1.11. Changes to the GTCs</h6>

                <p>Wayabank can make amendments to the GTCs at any time. Any changes shall be communicated to customers in advance in an appropriate manner. Should customers not agree to the changes, they can delete the Wayabank app from their smartphone and expressly declare to Wayabank that they wish to cease using the services.</p>



                <h6>1.12. Reservation of statutory regulations and local legal restrictions for usage</h6>

                <p>Any statutory provisions that govern the operation and use of smartphones, the Internet and other dedicated infrastructure shall remain reserved and shall also be applicable to these services from the time that they enter into force.</p>

                <p>The use of the services from outside Nigeria may be subject to local legal restrictions or, under certain circumstances, breach foreign legislation. The payment function shall in principle be limited to Nigerian territory and may not be utilized abroad.</p>

                <p>Customers shall acknowledge that circumstances may arise during the term of the business relationship that may legally obligate Wayabank to block assets, report the business relationship to a responsible authority or terminate the business relationship. Upon request, customers shall be obligated to provide Wayabank with information that it requires to meet its statutory clarification or reporting obligations.</p>



                <h6>1.13. Intellectual property</h6>

                <p>For the duration of the agreement, customers shall receive the non-transferable, non-exclusive right to use the Wayabank app. The content and scope of this right are governed by these GTCs. All intellectual property rights shall remain with Wayabank or the entitled third parties. Should customers breach third party intellectual property rights and Wayabank be held responsible, the customer in question shall fully indemnify Wayabank.</p>



                <p>1.14. Duration and termination</p>

                <p>The business relationship between customers and Wayabank shall be concluded for an indefinite period. Customers can delete the Wayabank app at any time and both contracting parties can terminate the business relationship in writing at any point.</p>



                <h6>1.15. Customer-initiated block</h6>

                <p>Customers shall be required to submit a request to Wayabank for their Wayabank credit and thus access to the payment function to be blocked. Any payments initiated prior to the time that this request is made shall be deemed to have been booked and cannot be reversed.</p>



                <h6>1.16. Changes to services and blocking of access by Wayabank</h6>

                <p>Wayabank can amend, update or enhance the services at any time. Wayabank shall also be authorized to fully or partially discontinue the operation of the Wayabank app or the customer’s access to the Wayabank app at any time without providing prior notification and shall likewise be entitled to restrict the Wayabank app’s availability for technical or legal reasons (e.g. due to legal or regulatory requirements, upon receipt of an official order or for security reasons).</p>



                <h6>1.17. Severability clause</h6>

                <p>Should a provision of these GTCs be fully or partially invalid or ineffective, this shall not affect the validity of the other provisions. In such cases, Wayabank and its customers shall undertake to replace the ineffective provision with a provision that best approximates the meaning and purpose of the original.</p>



                <h6>1.18. Applicable law and place of jurisdiction</h6>

                <p>To the extent permitted by law, all legal relationships between customers and Wayabank shall be exclusively subject to Nigerian substantive law with the exclusion of the law on the conflict of laws and any international treaties. Subject to the existence of mandatory statutory provisions to the contrary, the sole place of jurisdiction for all proceeding types shall be Lagos.</p>







                <h4>2. Payment functions</h4>


                <h6>2.1. No interest shall be paid on Wayabank credit.</h6>



                <h6>2.2. Topping up, releasing and processing of credit</h6>

                <p>Wayabank credit shall be topped up by customers via the options designated for this purpose in the app or via the SMS/text message solution. Depending on the selected top-up option, use of the payment function (see section 2.4) and the topping up of credit can coincide. However, such instances shall involve two transactions, with it first always being necessary to top up the Wayabank credit before making a subsequent payment. Any transaction fees or other charges associated with the topping up of credit must be borne by the customer in question.</p>

                <p>No interest shall be paid on Wayabank credit.</p>

                <p>Customers shall only be able to make payments using Wayabank credit if they have a positive balance.</p>

                <p>In cases in which a direct debit (LSV) is set up, customers shall issue Wayabank with authorization to disclose certain data to third parties for the purpose of a credit rating check.</p>



                <h6>2.3. Reference account</h6>

                <p>For regulatory reasons, released Wayabank credit must be transferred to a reference account. The reference account must be held in the name of the customer with a bank licensed in Nigeria.</p>



                <h6>2.4. Payment function</h6>

                <p>Customers can make cashless payments using their smartphone and the associated Wayabank credit at appropriately equipped shop cash registers (at points of sale – POS), vending machines, online, in other apps by saving Wayabank as a payment method with selected merchants and to other customers in accordance with the applicable limits.</p>

                <p>Upon making a payment using Wayabank credit, the relevant payment amount shall be deducted directly from the Wayabank credit. Customers shall acknowledge all payments booked to the Wayabank credit that have been executed in connection with their smartphone and in keeping with the following security levels. In the Wayabank app settings, customers shall be free to choose the amounts from which payments should be made a) automatically or b) after they have provided express confirmation (“OK” button). Customers can adjust the limits proposed and saved accordingly by Wayabank. Settings that have been made can be changed at any time. This does not apply to payments made with merchants for which customers have saved Wayabank as a payment method and where they have issued general payment approval (irrespective of the purchase amount). In such cases, the payment is executed automatically in accordance with the process defined by the merchant in question.</p>

                <p>In the case of P2P payments, the mobile number may also be used to find the other customer. Provided that customers have issued the relevant access approval, Wayabank shall be authorized to access existing contacts in the payer’s smartphone in connection with such payments.</p>

                <p>Upon saving Wayabank as a payment method, customers shall authorize the merchant in question to debit the relevant amount directly from their Wayabank credit without them being required to authorize individual charges. To save Wayabank as a payment method, customers must register with the relevant merchant. Authorization of this kind granted to a merchant can be revoked by the customer in the Wayabank app at any time. Customers can only renew expired or deactivated registrations with the merchant.</p>



                <h6>2.5. Debiting of payments</h6>

                <p>Customers shall acknowledge all payments made for the purchase of goods and services that have been made from their smartphone using Wayabank credit and registered as payments in the app.</p>



                <h6>2.6. Fees</h6>

                <p>The installation of the Wayabank app and the use of the associated services shall in principle be free of charge for customers.</p>

                <p>However, payments with Wayabank credit within the Wayabank platform will be charged a fee</p>

                <p>The topping up of credit via Nigerian bank and postal accounts will be charged a fee.</p>

                <p>Wayabank shall be authorized to charge fees for the use of other top up options, provided such options lead to Wayabank incurring costs itself. Customers shall be informed in the Wayabank app in a transparent and direct manner about any fees to be paid prior to making use of the fee-based top-up option. The relevant fees shall also be published at www.wayapaychat.com.</p>

                <p>Changes to fees and the introduction of new fees shall be communicated to customers in the Wayabank app. Such changes shall be deemed to have been accepted if customers do not terminate the agreement prior to the entry into force of the relevant amendment (section 1.14). Should agreements be terminated due to fee changes in accordance with this section, customers shall not be subjected to any disadvantages in terms of price.</p>



                <h6>2.7. Offsetting</h6>

                <p>Customers shall expressly agree that Wayabank can offset any outstanding claims against them against their existing credit with Wayabank.</p>



                <h6>2.8. Payment information</h6>

                <p>The total purchase amount, the time of the purchase and the location of the merchant (or POS) at which the payment was made shall be recorded in the Wayabank system. The transactions shall be visible in the Wayabank app for up to a maximum of 180 days.</p>



                <h4>Value-addedservices</h4>


                <h6>3.1. Mobile marketing campaigns</h6>



                <h6>3.1.1. Presentation of campaigns</h6>

                <p>Wayabank may present coupons, stamps cards and other campaigns (hereinafter referred to as “campaigns”) to customers in the Wayabank app where they can be seen, managed and redeemed. Here, a differentiation is made between the following campaign types:</p>

                <p>Campaigns launched by Wayabank or the Wayabank system (hereinafter referred to as “issuer campaigns”).</p>
                <p>Campaigns of Wayabank launched together with a third party provider (hereinafter referred to as “issuer value-added campaigns”).</p>
                <p>Campaigns launched by a third party provider (hereinafter referred to as “third party-provider campaigns”).</p>
                <p>The presentation, display, management and redemption of issuer campaigns and issuer value-added campaigns shall not require an opt-in from customers. These campaigns can thus be presented to all customers.</p>


                <h6>3.1.2. Period of validity of campaigns</h6>

                <p>The validity of campaigns shall be as displayed in the Wayabank app on the smartphone or phone screen.</p>

                <p>Certain campaigns must be activated in advance by customers in the Wayabank app before the relevant offers can be redeemed. Such cases shall be noted accordingly as part of the respective campaign. Activated campaigns may be deactivated by Wayabank if the associated offers are not redeemed within 10 days.</p>

                <p>It shall be possible for customers to redeem other campaign offers without activating them in advance in the Wayabank app. Many campaigns can only be redeemed upon making payments with the Wayabank app.</p>



                <p>The activation of a campaign or the receipt of a campaign offer that can be redeemed without activation shall not always mean that customers are entitled to make use of a discount or non-cash benefit, as the number of redemptions may be limited by the involved third party provider. Such cases shall be noted accordingly as part of the respective campaign.</p>



                <p>In most cases, campaigns shall be redeemed automatically upon customers making a payment with the Wayabank app without them being required to do anything. In some instances, however, customers may be required to present a campaign to the business customer in the Wayabank app or enter the campaign themselves at a terminal or in an online shop. Such cases shall be noted accordingly as part of the respective campaign.</p>

                <p>Upon the redemption of a campaign offer with a discount, the discount shall either be deducted directly from the amount to be paid or reimbursed to customers after the payment is made in the form of a cash back credit.</p>



                <h6>3.1.3. Sharing of campaigns</h6>

                <p>Wayabank may provide customers with the option to forward campaigns to other individuals, to receive campaigns from other individuals or to share campaigns with other individuals.</p>



                <h6>3.2. Customer loyalty cards</h6>

                <p>Customers have the option to save or activate selected employee ID cards, customer loyalty programmes and other incentive-based offers from third party providers (hereinafter referred to as “customer loyalty cards”) in the Wayabank app. Saved or activated customer loyalty cards can be removed from the Wayabank app by customers at any time.</p>

                <p>Wayabank shall also be authorized to remove saved customer loyalty cards from the Wayabank app if a customer loyalty card expires or the customer loyalty card in question is generally no longer available to be saved in the Wayabank app.</p>

                <p>Customers shall acknowledge that the benefits associated with the use of certain customer loyalty cards shall be presented directly in the Wayabank app in the form of campaigns. Customers shall only receive such campaigns if they have provided their prior consent for the presentation of third party offers (see section 3.1.1).</p>



                <h6>3.3. Other value-addedservices</h6>

                <p>In addition to campaigns and customer loyalty cards, Wayabank can offer other value-added services in the Wayabank app at any time.</p>



                <h6>3.4 Liability for value-added services</h6>

                <p>The respective third party provider shall be responsible for contents, offers, messages from third party provider campaigns, customer loyalty cards and any other value-addedservices in the Wayabank app.</p>



                <p>Wayabank shall have no influence on the fulfillment of services offered by third party providers. Wayabank shall also accept no liability for campaigns that cannot be redeemed with third party providers or for discounts or benefits that are not granted in connection with the saving of customer loyalty cards. Such cases may include the failure to grant employee discounts or instances in which loyalty points remain outstanding, are lost or disappear.</p>



                <p>Wayabank shall endeavour to ensure the uninterrupted availability and fault-free usability of value-added services in the Wayabank app. Wayabank cannot, however, guarantee this at all times. In the case of an interruption in availability, one possible consequence may be that it is no longer possible to automatically redeem discounts or automatically collect loyalty points within the framework of the payment process. Provided that Wayabank exercises the level of care and due diligence customary in the industry, customers shall bear any losses suffered due to interruptions of this kind.</p>



                <h4>4. Data protection</h4>


                <h6>4.1. Scope</h6>

                <p>Wayabank shall be subject to Nigerian data protection legislation (in particular the Federal Act on Data Protection (FADP) and the Federal Ordinance on Data Protection (FODP)) in connection with the procurement, processing and use of personal data of its customers.</p>

                <p>Wayabank attaches great importance to data protection. In this “Data protection” section, customers are provided with information on data processing and the data flows associated with the use of the Wayabank app.</p>

                <p>Wayabank shall be responsible vis-à-vis customers for ensuring that data is collected and used in compliance with the relevant data protection legislation in accordance with the provisions stipulated in the “Data protection” section.</p>



                <h6>4.2. Requirements for the use of the Wayabank app</h6>

                <p>To enable Wayabank to process customer payments and provide value-added services, customers must save the following master data in the Wayabank app upon registering: smartphone number, name, place of residence, nationality, date of birth and e-mail address.</p>



                <h6>4.3. Making payments with the Wayabank app</h6>

                <p>Should a customer wish to initiate a payment via the Wayabank app at a point of sale (hereinafter referred to as a “POS”) of a business customer, a connection shall be established in the Wayabank system between the customer’s Wayabank app and the relevant business customer.</p>

                <p>The specific way in which the connection is established between the POS and Wayabank app shall differ depending on the type of POS.</p>

                <p>Shop cash register using a Wayabank terminal (technical installation at the POS that facilitates the establishment of a connection and the exchange of data between the customer’s smartphone and the POS);</p>

                <p>Entry of a code displayed at the POS or scanning of a QR code;</p>

                <p>Internet: by entering a code displayed in the online shop or scanning a QR code;</p>

                <p>Automatic establishment of a connection for recurring Wayabank payments made by customers registered in an online shop. This arrangement is set up at the initiative of the customer;</p>

                <p>In an app: automatic establishment of a connection at the initiative of the customer;</p>

                <p>At vending machines: same as at shop cash registers and online.</p>

                <p>The POS informs the Wayabank system what amount should be charged. The Wayabank system then sends a payment request to the customer’s Wayabank app. In the Wayabank app settings, customers shall be free to choose the amounts from which payments should be made a) automatically, b) after they have provided express confirmation (“OK” button) or c) following the entry of the Wayabank PIN (see section 2.4).</p>



                <p>Upon the payment being approved by the customer, the specific amount shall be debited from the Wayabank credit. At the time at which the amount is debited, no connection shall be established with a bank or postal account, even if customers have saved a reference account in the Wayabank app.</p>

                <p>The total purchase amount, the time of the purchase and the location of the POS at which the payment was made shall be recorded by Wayabank. The Wayabank system shall generate a credit in favour of the business customer and the funds shall be transferred to the business customer’s account.</p>

                <p>Wayabank shall not receive any details of the goods purchased unless the transfer of such data is governed in accordance with section 4.4.</p>

                <p>Without the express consent of customers, Wayabank shall not disclose any personal data to the involved business customers and/or third parties unless the transfer of such data is governed in accordance with section 4.4 or 4.5.</p>



                <h6>4.4. Saving of customer loyalty cards</h6>

                <p>Customers shall have the option to save or activate physical and purely digital customer loyalty cards of various business customers directly in the Wayabank app. Should customers wish to do so, they must establish the necessary settings and make the required entries. Upon saving or activating a customer loyalty card in the Wayabank app, customers shall be deemed to have provided their express consent to the use of the card in question. These cards shall subsequently be automatically taken into account during payment procedures performed with the Wayabank app, provided this has been made technically possible by the respective customer loyalty card issuer. Customers can deactivate the use of the customer loyalty card in the Wayabank app at any time.</p>

                <p>In such cases, the payment procedure shall also be based on section 4.3.</p>



                <p>If a customer loyalty card is saved in the Wayabank app and a payment is made using the Wayabank app resulting in a customer gaining a possible benefit from the use of the customer loyalty card (points, discount, etc.), the issuer of the customer loyalty card or a third party legally working on its behalf shall be provided with the same data as it would receive had the customer presented the customer loyalty card physically.</p>



                <p>Wayabank shall transmit the identification number of the customer loyalty card to the business customer or its affiliated third party and, depending on the customer loyalty card used in the specific instance, also the basic payment data, including the time stamp, amount and any discounts or points granted in connection with the use of the customer loyalty card. The use of this data by the business customer involved in the specific case shall be governed exclusively by the contractual relationship between the customer and the business customer or between the customer and the third party affiliated to the business customer. The

                business customer shall be responsible for ensuring the contractually compliant handling of customer data and for obtaining the required authorizations.</p>



                <h6>4.5. Redemption of mobile marketing campaign offers</h6>

                <p>In order to allow for the automatic redemption of campaign offers for the granting of a discount or non- cash benefit, data must be exchanged between the Wayabank system and the business customer.</p>

                <p>The data transmitted shall depend on the system in which the campaign offer is redeemed and the discount or non-cash benefit is applied.</p>

                <p>In cases in which campaign offers are redeemed in the system of the business customer, Wayabank shall transmit the campaign identification number to the business customer. The business customer shall then apply any discount or non-cash benefit for the customer. Here, the business customer shall receive the same information that it would have received had the customer presented the campaign identification number (e.g. in the form of a bar code).</p>

                <p>f campaign offers are redeemed in the Wayabank system, the discount or non-cash benefit shall be applied in the Wayabank system and communicated to the business customer so that it can apply the benefit in its own system (e.g. deduction of a discount).</p>

                <p>The contractual relationship between the business customer and the customer alone shall determine whether the business customer provides further data to Wayabank (e.g. information for the redemption of campaign offers that had previously been transmitted from the Wayabank system to the business customer or details of purchased goods on the basis of which campaign offers can be redeemed in the Wayabank system). The business customer shall be responsible for ensuring the contractually compliant handling of customer data and for obtaining the required authorizations.</p>



                <h6>4.6. Collection and use of data in order to improve the Wayabank app</h6>

                <p>Wayabank collects and uses data in order to provide and improve the Wayabank system. These activities involve data that the Wayabank app can access in accordance with the customer’s smartphone settings (e.g. receipt of BLE signals, geolocation) as well as technical data and information generated during the use of the Wayabank app. Wayabank shall never disclose this personal data to business customers and/or third parties without the express approval of customers in the Wayabank app. Instead, it shall use this data exclusively to provide and improve its own service.</p>



                <h6>4.7. Google Firebase</h6>

                <p>Wayabank shall use the Google Firebase Software Development Kit (SDK) of Google Inc. (“Google”) in the Wayabank app. It shall do so in order to analyse user behavior within the app with the objective of optimizing the Wayabank app on an ongoing basis and gearing its features to user requirements.</p>

                <p>The customer shall have the option to deactivate the collection and transmission of usage data to Google at any time in the Wayabank app under the settings.</p>

                <p>The information gathered by the SDK on the use of the Wayabank app, in particular</p>

                <p>the analytics ID (random value on the basis of which Wayabank can identify the customer)</p>

                <p>the client ID (random value which identifies the used device and allows Google to summarize sent events in a device session) which, however, does not allow inferences to be made about the user’s device</p>

                <p>key device details (brand, type, screen, memory)</p>

                <p>information on the platform (e.g. iOS and Android version)</p>

                <p>the version of the installed Wayabank app</p>

                <p>he type and version of the used Internet browser, where applicable</p>

                <p>the IP address of the accessing computer (shortened so that it can no longer be assigned to the specific user)</p>

                <p>shall be transferred to Google servers in the US and saved there. This data shall be assessed by Google in order to generate reports on the usage of the Wayabank app and to allow it to provide further services related to the use of the Wayabank app.</p>

                <p>Customers acknowledge that Google shall transfer this information to third parties, provided this is required by law or if third parties are to process this data on behalf of Google. Google shall not link the IP address of the customer to other Google data under any circumstances. The IP addresses are anonymize (shortened by three characters) to ensure that they cannot be assigned to the customer.</p>



                <h6>4.8. Third party provider campaigns</h6>

                <p>Customers can expressly issue their consent (opt-in) to Wayabank for third party-provider campaigns to be displayed to them in the Wayabank app. This shall in turn enable them to activate and redeem such campaigns (see also section 3.1.1). In opting in, customers shall also expressly agree that Wayabank can collect and analyse data for the personalized presentation of third party-provider campaign offers.</p>

                <p>Customers can opt in or opt out either upon being explicitly asked in this regard when installing the Wayabank app and/or at a later time by adjusting their settings in the Wayabank app. The provision of consent by customers shall allow Wayabank to send them third party-provider campaign offers that are tailored to their personal interests.</p>

                <p>Customers shall acknowledge that third party-provider campaign offers can only be displayed and redeemed with an opt-in in the Wayabank app.</p>

                <p>Even in cases in which customers opt in, Wayabank shall not disclose any personal customer data to involved business customers and/or third parties if customers have not issued their express consent to the forwarding of such data in the Wayabank app (see also section 4.4 in this regard). Unless such consent is provided, involved business customers shall only receive access to anonymize data.</p>

                <p>Customers can be provided with the option to expressly consent to the sending of personalized third party- provider campaign offers via e-mail.</p>



                <h6>4.9. Involvement of third parties</h6>

                <p>Customers shall expressly agree that Wayabank may involve third parties (e.g. payment service providers) for the provision of its services and that, where necessary, customer data may be disclosed within the framework of such relationships. Wayabank undertakes to select, instruct and monitor such service providers in a prudent manner.</p>

                <p>Third parties may only use such data in accordance with the applicable privacy policy on behalf of Wayabank. It shall be forbidden for third parties to use the data for their own purposes.</p>

                <p>Wayabank shall assume responsibility vis-à-vis customers for ensuring that the data is handled in compliance with the relevant data protection legislation.</p>



                <h6>4.10. Storage and deletion</h6>

                <p>Personal customer data stored by Wayabank shall be deleted or anonymize if it is no longer required for the provision of services and no later than four years after the time at which it is saved.</p>

                <p>Should the Wayabank app not be used for a period of two years, Wayabank shall assume that customers have deleted the Wayabank app from their smartphone. In such cases, the personal data saved by Wayabank shall also be deleted or anonymize.</p>

                <p>Should customers subsequently decide not to make use of personalized campaigns (opt-out), all activated coupons, stamp cards and other campaign offers in the Wayabank system shall be permanently deleted or anonymize six months after the opt-out is issued and customers shall no longer be able to take advantage of any associated discounts and benefits.</p>

                <p>Data that must be stored for longer in order to ensure the fulfillment of legal obligations of Wayabank shall not be subject to the provisions of this section 4.10. 4.11. Information and disclosure rights. Should customers have any questions relating to the handling of personal data, they can contact Wayabank via the following e-mail address: info@wayabank.ng</p>
              </div>
            </div>
          </Fade>
        </div>
        <article >
          <div className="entry-content" dangerouslySetInnerHTML={{ __html: settings.terms_of_use }} />
        </article>
        <PageFooter />
      </div>
    </div>
  )
}

export default TermsOfUse