import { customBaseUrl, httpGet } from './http';


//just a test again
export const getBusinessTypes = async () => {
  const res = await httpGet(
    `${customBaseUrl.authUrl}/api/v1/business/type/find/all?page=0&size=50`,
    'CORPORATE',
    '',
    true
  );
  console.log('res.businessTypeList', res.businessTypeList)
  return res.businessTypeList;
};
