import dotenv from 'dotenv';

dotenv.config();

const {
  IS_LIVE,
  REACT_APP_CORPORATE_APP,
  REACT_APP_PERSONAL_APP,
  REACT_APP_BASE_URL,
  REACT_APP_GRAM_URL,
  REACT_APP_POS_URL,
  REACT_APP_WAYAPAY_URL,
  REACT_APP_WAYAQUICK_APP,
  REACT_APP_WAYAQUICK_POS,
  REACT_APP_WAYAGRAM_APP,
} = process.env;

export const envConfig = {
  isLive: IS_LIVE === 'live' ? true : false,
  corporateAppUrl:
  REACT_APP_CORPORATE_APP || '',
  wayaquickAppUrl: 
    REACT_APP_WAYAQUICK_APP || '',
  wayaposAppUrl: 
    REACT_APP_WAYAQUICK_POS || '',
  wayagramAppUrl:
    REACT_APP_WAYAGRAM_APP || '',
  personalAppUrl: REACT_APP_PERSONAL_APP || '',
  baseUrl: REACT_APP_BASE_URL || '',
  gramUrl: REACT_APP_GRAM_URL || '',
  posUrl: REACT_APP_POS_URL || '',
  wayapayUrl: REACT_APP_WAYAPAY_URL || '',
};
