import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import validator from "validator";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import { Browser, getUserInfo } from "../../utils/locationInfo";
import "./index.scss";
import ToggleInput from "../../componentParts/ToggleInput";
import { customBaseUrl, httpPost, httpGet } from "../../services/http";
import Button from "../../componentParts/Button";
import ForgotPassword from "../../componentParts/Modals/ForgotPassword";
import ResetPassword from "../../componentParts/Modals/ResetPassword";
import ResendOtp from "../../componentParts/Modals/Otp/ResendOtpOutside";
import VerifyOtp from "../../componentParts/Modals/Otp/VerifyOtp";
import Logo from "../../assets/images/newLogo.svg";
import { envConfig } from "../../utils/envConfig";
import { encrypt } from "../../utils/helper";

const InviteCreate = () => {
  const history = useHistory();
  //   const [location, setLocation] = useState({});
  const [data, setData] = useState({
    email: "",
    phoneNumber: "",
    password: "",
    roleId: "",
    firstName: "",
    lastName: "",
    confirmPassword: "",
  });
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [confirmOtp, setConfirmOtp] = useState("");

  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sharedPost, setSharedPost] = useState(false);
  const [sharedPostId, setSharedPostId] = useState(null);
  const [otp, setOtp] = useState("");
  const [showPin, setShowPin] = useState(false);
  const [hideIt, setHideIt] = useState(false);
  const [comfirmPassword, setComfirmPassword] = useState("");
  //   const getLocationInfo = async () => {
  //     const userBrowserName = Browser.getBrowserName();
  //     const userInfo = await getUserInfo();
  //     setLocation({
  //       device: userBrowserName,
  //       userLocation: userInfo,
  //     });
  //   };
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const roleId = searchParams.get("roleid");
    const email = searchParams.get("email");
    const phoneNumber = searchParams.get("phone");
    if (roleId) {
      setData((prev) => ({ ...prev, roleId }));
      setHideIt(true);
    }
    if (email) {
      setData((prev) => ({ ...prev, email }));
    }
    if (phoneNumber) {
      setData((prev) => ({ ...prev, phoneNumber }));
    }
  }, [location.search]);

  const completeSignup = async () => {
    setLoading(true);
    const postData = {
      otp: Number(otp),
      phoneOrEmail: phone,
    };
    const res = await httpPost(
      "/api/v1/auth/verify-otp",
      postData,
      customBaseUrl.authUrl
    );
    if (res.status) {
      setLoading(false);
      setOtp("");
      setPhone("");
      setShowVerifyOtp(false);
      swal("Done", res.message, "success").then(() => {
        history.push("/login");
      });
    } else {
      setLoading(false);
      swal("Oops!", res.message, "error");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (validator.isEmpty(data.email)) {
      setLoading(false);
      swal("Oops!", "Email cannot be empty", "error");
      return;
    }
    if (validator.isEmpty(data.password)) {
      setLoading(false);
      swal("Oops!", "Password cannot be empty", "error");
      return;
    }
    if (!validator.equals(data.password,data.confirmPassword)) {
      setLoading(false);
      swal("Oops!", "Password doesn't match", "error");
      return;
    }
    const res = await httpPost(
      "/api/v1/auth/invite/updatePassword",
      { ...data },
      customBaseUrl.authUrl
    );
    console.log(res);
    if (res?.status) {
        history.push('/login');

      // httpPost(
      //   '/api/v1/history/save',
      //   {
      //     city: location?.userLocation?.city,
      //     country: location?.userLocation?.country_name,
      //     device: location?.device,
      //     id: 0,
      //     ip: location?.userLocation?.IPv4,
      //     province: location?.userLocation?.state,
      //     userId: res.data.user.id,
      //   },
      //   customBaseUrl.authUrl
      // );
      // if (sharedPost) {
      //   history.push(`/post/${sharedPostId}`);
      // } else {
      //   // history.push('/products');
      //   const encoded = encrypt(res?.data?.token);
      //   if (res.data.corporate) {
      //     window.location.href = `${envConfig.corporateAppUrl}/login?from=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}`;
      //   } else {
      //     window.location.href = `${envConfig.personalAppUrl}/login?from=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.user.id}&pinCreated=${res.data?.pinCreated}`;
      //   }
      // }
    }
     else {
      setLoading(false);
      swal(
        "Oops!",
         res?.message,
        "error"
      )
    }
  };

  //   useEffect(() => {
  //     getLocationInfo();
  //     return () => {
  //       setLocation({});
  //     };
  //   }, []);
  console.log("data", data);
  return (
    <div id="login-bg">
      <div id="login-modal" className="my-auto modal-body-rs-log col-12">
        <div className="waya-modal-body-log">
          <div className="header-sec-modal-log">
            <img className="header-sec-logo-log mx-auto" src={Logo} alt="" />
          </div>
          <h1 className="modal-header-data-log">Create Account</h1>

          <form>
            <div className="inputbox-with-one-input-log">
              <input
                value={data.email}
                disabled
                placeholder="Email"
                type="text"
                // onChange={(e) => setData({ ...data, email: e.target.value })}
              />
            </div>
            <div className="inputbox-with-one-input-log">
              <input
                value={data.phoneNumber}
                disabled
                placeholder="Phone"
                type="text"
                onChange={(e) => setData({ ...data, phone: e.target.value })}
              />
            </div>
            <div className="inputbox-with-one-input-log">
              <input
                placeholder="First Name"
                type="text"
                onChange={(e) =>
                  setData({ ...data, firstName: e.target.value })
                }
              />
            </div>
            <div className="inputbox-with-one-input-log">
              <input
                placeholder="Last Name"
                type="text"
                onChange={(e) => setData({ ...data, lastName: e.target.value })}
              />
            </div>
            <div className="inputbox-with-one-input-log toggle-pass-wrapper">
              <ToggleInput
                placeholder="Password"
                type="password"
                onChange={(e) => {
                  setData({ ...data, password: e.target.value });
                }}
              />
            </div>
            <div className="inputbox-with-one-input-log toggle-pass-wrapper">
              <ToggleInput
                placeholder="Comfirm Password"
                type="password"
                onChange={(e) => {
                  setData({ ...data, confirmPassword: e.target.value });
                }}
              />
            </div>
          </form>
          <div className="submit-modal-btn-wrap-log">
            <Button
              type="button"
              loading={loading}
              onClick={handleSubmit}
              content="Create Account"
            />
          </div>
          <div
            style={{
              fontSize: "14px",
              width: "80%",
            }}
            className="mx-auto d-flex  login-footer justify-content-between text-black"
          >
            <span>© Wayapay</span>
            <span>About</span>
            <span>Insights</span>
            <span>Terms</span>
            <span>Privacy</span>
          </div>
        </div>
      </div>

      {showVerifyOtp ? (
        <VerifyOtp
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Verify Your Account"
          description="Please input the OTP sent to your email address or phone number"
          isResendOtp
          buttonLabel="Verify"
          handleSubmit={completeSignup}
          loading={loading}
          numInputs={6}
          resendOtp={setShowResendOtp}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default InviteCreate;
