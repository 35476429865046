import React from 'react';

const TicketPages = () => {

  return (
    <div style={{ height: 500, overflowY: 'auto' }}>
      <div className="d-flex justify-content-center">
        <h2>Complaint Ticket</h2>
      </div>
      <div className="App" style={{ width: 1000, textAlign: 'center' }}>
        {/* <h3>Complaint Ticket Form</h3> */}
        <iframe
          // src="https://forms.zohopublic.com/wayabank/form/ComplaintTicketingForm/formperma/ut_-h9uwYeIcgRv0goP-ZanRBHFZwWP9_udhzLdUUKI?zf_rszfm=1"
          src={'../support.html'}
          title="Compl"
          width="100%"
          height={1000}
          style={{
            maxWidth: 640,
            width: '100%',
            overflow: 'auto',
          }}
        />
      </div>
      <iframe srcDoc='support.html' id="serviceFrameSend" src="support.html" width="1000" height="1000" />

      <div style={{ height: 200 }} />
    </div>
  );
};

export default TicketPages;
