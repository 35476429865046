import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import validator from "validator";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Browser, getUserInfo } from "../../utils/locationInfo";
import "./index.scss";
import ToggleInput from "../../componentParts/ToggleInput";
import { customBaseUrl, httpPost, httpGet } from "../../services/http";
import Button from "../../componentParts/Button";
import ForgotPassword from "../../componentParts/Modals/ForgotPassword";
import ResetPassword from "../../componentParts/Modals/ResetPassword";
import ResendOtp from "../../componentParts/Modals/Otp/ResendOtpOutside";
import VerifyOtp from "../../componentParts/Modals/Otp/VerifyOtp";
import Logo from "../../assets/newlog.svg";
import { envConfig } from "../../utils/envConfig";
import { encrypt } from "../../utils/helper";
import { isExpired, decodeToken } from "react-jwt";
import ResetPasswordModal from "../../componentParts/Modals/resetNotify";
import { hideLoader, showLoader } from "../../utils/loader";
import Swal from "sweetalert2";
const VerifyBvn = (props) => {
  const history = useHistory();
  const [code, setCode] = useState("");  
    const handleSubmit = async () => {
      const {
        location: { search },
      } = props;
      const trackId = (new URLSearchParams(search).get("trackingId"));
      const res = await httpPost(
            `/no-auth/verifyBvn?code=${code}&trackingId=${trackId}`,
            {},
            customBaseUrl.kycUrl
        )
        if (res?.status) {
          Swal.fire('Done', res?.message);
          window.close();
        } else {
          Swal.fire('Oops!', res?.message);
        }
    }
   return (
    <div id="login-bg">
      <div id="login-modal" className="my-auto modal-body-rs-log col-12">
        <div className="waya-modal-body-log">
        <div className="inputbox-with-one-input-log">
          <input
            placeholder="Enter your BVN here"
            maxLength={11}
            minLength={11}
            required
            type="text"
            value={code}
            onChange={(e) =>setCode(e.target.value)}
          />
        </div>
          <div className="text-center mt-3">
            <button className="btn btn-primary" onClick={handleSubmit}>Submit</button>
          </div>
        </div>      
    </div>
    </div>
  );
};

export default VerifyBvn;
