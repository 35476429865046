const crypto = require('crypto');
// const key = crypto.randomBytes(32);
const iv = crypto.randomBytes(16);
const key = {
  type: 'Buffer',
  data: [
    51, 152, 235, 68, 51, 42, 62, 50, 40, 136, 55, 165, 108, 253, 6, 132, 33,
    161, 12, 35, 102, 209, 72, 212, 33, 225, 74, 14, 109, 149, 149, 227,
  ],
};

export const checkAcess = (value) => {
  const regexFormat = /^[[0-9+-]+$/;
  console.log(regexFormat.test(value));
  return regexFormat.test(value);
};

export function encrypt(text) {
  let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return {
    iv: iv.toString('hex'),
    encryptedData: encrypted.toString('hex'),
  };
}
