import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import validator from "validator";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Browser, getUserInfo } from "../../utils/locationInfo";
import "./index.scss";
import ToggleInput from "../../componentParts/ToggleInput";
import { customBaseUrl, httpPost, httpGet } from "../../services/http";
import Button from "../../componentParts/Button";
import ForgotPassword from "../../componentParts/Modals/ForgotPassword";
import ResetPassword from "../../componentParts/Modals/ResetPassword";
import ResendOtp from "../../componentParts/Modals/Otp/ResendOtpOutside";
import VerifyOtp from "../../componentParts/Modals/Otp/VerifyOtp";
import Logo from "../../assets/newlog.svg";
import { envConfig } from "../../utils/envConfig";
import { encrypt } from "../../utils/helper";
import { isExpired, decodeToken } from "react-jwt";
import ResetPasswordModal from "../../componentParts/Modals/resetNotify";
import { hideLoader, showLoader } from "../../utils/loader";
const BvnCallback = (props) => {
  const history = useHistory();
  const [location, setLocation] = useState({});
  const [data, setData] = useState({
    emailOrPhoneNumber: "",
    password: "",
  });
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [confirmOtp, setConfirmOtp] = useState("");

 
  const [loading, setLoading] = useState(false);
    const [sharedPostCode, setSharedPostCode] = useState(null);
  const [success, setSuccess] = useState('');

  // https://staging.wayabank.ng/bvncallback?code=b3c92371-2c86-49c7-883c-05406ac6542f&acr_values=otp&scope=profile&session_state=e20e65df11159a1a97959e4c56aba6132a137c950f7c15618c789e9cca6b50f2.061472f7-5534-45da-9d94-423d4820e329&sid=87acd418-5698-4be7-b272-f506eec44e91
  // http://localhost:3000/login?from=web&iv=468598c358c8a19828b61b875d0c50ff&token=ceabdfe05b3b6c37a015ef21ea8f87418005925cd6aed72a53bfccacdd10f2bffb96d8331da440f55f642dfa741517880452fe961de58a2de84a4308c250d96a23a8de796c7592a51f12a435656a614236b09e03db0fef20f8b2cbc5f9d2cd76de97c699c7120d5b7663ece9585932e9e3da464e34b39f5ff15c98c35672bfe28bc9afd7945ce5e0b911b66c81e0c3f8b1d8f48424c7b0f922973493f55ee116149c547791ddb914cd2b5c6d3e949d6f300235fc9ee93f33db99ab46876e48366a4a906972194eee8b0e8e6a39367e94f8ae4f9d6e9b58104964e9ccc92f9d22b806f92118891ddfe0026279acb0417180eb6d0549a2e5f4fdd2c04710140ea98406a1fbaab3940b013099d419fbc246858070313c19cdd0dd43ad62cdda00af03ea6325c4413b7446a4daa580f55bc5de07a8b9529adfa043d63662a663e0e8b9375410a335a105381068c7a246598cc1b645cd7fff46eee62fc0ea29278d00fb3dd736953d9e59fd4242d4e8cc5b84ba425297c689abafc75c74801e0f579d12ac403c134215a589b208c3155a7d2e08d0db4ff3f4e5eb96c38cae0dfdba0470ab3a97ff2edaec206386cef758d6a68a395bd530928a0bc0281213f2b978b9f63ba26e75d09897090f69af7a6bd7f4&userId=1645&pinCreated=true
  // https://staging.wayabank.ng/bvncallback?code=94f9d824-1985-4d58-9449-c0e1483549cf&acr_values=otp&scope=profile+bvn&state=271fcdd4-e974-4ddc-913a-9d6abb362d23&session_state=7b955fa8d34f3fddf977ca44dbe751add937101355215ce8cb1f51fac17ca9b1.1a2e317f-0e84-43d0-928e-48adcc7ca0d8&sid=bc0102df-267f-4f2e-be49-ab963c00832b
  https://staging.wayabank.ng/bvncallback?code=033eb42f-652d-44cc-a01a-907e2a74c27a&acr_values=otp&scope=profile+bvn&state=f06eadfa-219e-428d-972d-65e3a9fa8bae&session_state=c33ebd2e277909e7616712968fda881b27477ba626dcc3dfc3c6df829de22699.8fe76c11-8e82-4a0b-b1f9-cdd814534029&sid=24487dc4-530a-4b5f-a594-850c08b1baf8
  useEffect(() => {
    console.log('lll')
    const {
        location: { search },
      } = props;
      if (new URLSearchParams(search).get('acr_values') === 'otp') {
        const code = (new URLSearchParams(search).get("code"));
        const state = (new URLSearchParams(search).get("state"));
        const client = (new URLSearchParams(search).get("client"));
        verifyOtp(code, state, client);
        console.log('gets code', code);
        return;
      }
    
  }, []);

  const verifyOtp1 = () => {
    const token = localStorage.getItem('token');
    const data = {
        code: sharedPostCode,
        token
    };
    console.log('otp', data);
  }
  const verifyOtp = async (code, trackingId, clientType) => {
    setLoading(true);
    showLoader();
    const res = await httpPost(
      `/no-auth/fetch-bvn-with-consent?code=${code}&trackingId=${trackingId}`,
      {},
      customBaseUrl.kycUrl,
      clientType
    );
    console.log('lloog', res)
    if (res?.status !== false) {
      hideLoader();
        setSuccess(true)
        setInterval(() => {
          window.close();
        }, 3000)
    } else {
      hideLoader();
        swal('Oops!', res?.message, 'error');
        // window.close()
        setInterval(() => {
          window.close();
        }, 3000)
        setSuccess(false)
      }
    };
    const handleClose = () => {
      window.close();
      history.push(`${envConfig.wayapayUrl}/bvn`);
    }
   return (
    <div id="login-bg">
        {success === true ?
      <div id="login-modal" className="my-auto modal-body-rs-log col-12">
        <div className="waya-modal-body-log">
          <div className="header-sec-modal-log">
            <img className="header-sec-logo-log mx-auto" style={{ width: '70%', height: 60 }} src={Logo} alt="" />
          </div>
          <h1 className="modal-header-data-log">SUCCESSFUL</h1> 
          <div className="text-center mt-3">
            <button className="btn btn-primary" onClick={handleClose}>Close</button>
          </div>
        </div>
      </div>:success === false? <div>Verification failed</div>: "Verifying"}
      
    </div>
  );
};

export default BvnCallback;
