import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import './index.scss';
import Button from '../Button';

const ResetPasswordModal = (props) => {
  const { showModal, hideModal, setForgotPassord, reset } = props;
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setForgotPassord(true)
    hideModal(false)
    reset(true)
  };

  return (
    <div id='reset-password-modal'>
      <Modal isOpen={showModal} toggle={() => hideModal(false)}>
        <div className='modal-body-rs col-sm-12 col-md-10'>
          <div className='header-sec-modal'>
            <input
              type='image'
              onClick={() => hideModal(false)}
              className='header-img1'
              src='./assets/image/back.png'
              alt=''
              style={{ opacity: 0 }}
            />
            <img
              className='header-sec-logo'
              src='./assets/image/appLogo.png'
              alt=''
            />
            <input
              type='image'
              onClick={() => hideModal(false)}
              className='header-img1'
              src='./assets/image/x.png'
              alt=''
              style={{ opacity: 0 }}
            />
          </div>

          <div className='waya-modal-body'>
            <h4 className='modal-header-data' style={{color:'#ff4400'}}>Your Password has expired</h4>

            <h6
              className='text-secondary text-center'
              style={{ marginBottom: '50px' }}
            >
              Your password have exceeded 90days. Kindly change your password to continue
            </h6>

            <div
              className='submit-modal-btn-wrap'
              style={{ marginBottom: '50px' }}
            >
              <Button
                type='button'
                loading={loading}
                onClick={handleSubmit}
                content='Change Password'
                className='btn-primary'
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

ResetPasswordModal.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  center: PropTypes.bool.isRequired,
};

export default ResetPasswordModal;
