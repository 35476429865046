import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import validator from 'validator';
import { Modal } from 'reactstrap';
import './index.scss';
import { customBaseUrl, httpGet, httpPost } from '../../services/http';
import Button from '../Button';
import { checkAcess, encrypt } from '../../utils/helper';
// import Logo from '../../assets/images/wayaBankLogo1.png';
import Logo from '../../assets/images/newLogo.svg';
import { decodeToken } from 'react-jwt';
import VerifyOtp from './Otp/VerifyOtp';
import ResetPassword from './ResetPassword';
import ResendOtp from './Otp/ResendOtpOutside';
import { envConfig } from "../../utils/envConfig";
import { Browser, getUserInfo } from '../../utils/locationInfo';
import { useHistory } from 'react-router-dom';
import { hideLoader, showLoader } from '../../utils/loader';
import { ProfileOutlined, UserOutlined } from '@ant-design/icons';


const SelectAccount = (props) => {
  const {
    showModal,
    hideModal,
    userData,
    isPersonal,
  } = props;
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [phone, setPhone] = useState("");
  const [confirmOtp, setConfirmOtp] = useState("");
  const [showVerifyOtp, setShowVerifyOtp] = useState(false);
  const [showResendOtp, setShowResendOtp] = useState(false);
  const [sharedPost, setSharedPost] = useState(false);
  const [sharedPostId, setSharedPostId] = useState(null);
  const [otp, setOtp] = useState("");
  const [location, setLocation] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const getLocationInfo = async () => {
    const userBrowserName = Browser.getBrowserName();
    const userInfo = await getUserInfo();
    setLocation({
      device: userBrowserName,
      userLocation: userInfo,
    });
  };

  const getAccounts = async () => {
    // if (isPersonal) return handleSubmit();
    showLoader();
    const res = await httpPost(`/api/v1/profile/fetchSubscriptions/${userData?.user?.id}`,
    {},
    customBaseUrl.authUrl
    );

    if(res?.status) {
      hideLoader();
      const accountsList = res?.data?.filter(e=> e?.profile?.corporate === !isPersonal)
        if(accountsList?.length === 0) {
            swal(isPersonal ? 'Personal account does not exist for this user' : 'Corporate account does not exist for this user');
            hideModal(false);
            return;
        }
        if(accountsList?.length === 1) return setDefultAccount(accountsList[0]);
        setAccountList(accountsList);
        console.log('gggg', accountsList);
        setIsLoading(false);
    } else {
      hideLoader();
    }
  };




  const setDefultAccount = async (e) => {
    showLoader();
    const res = await httpPost(`/api/v1/profile/makeAccountDefault/${e?.user?.id}?id=${e?.id}`,
    {},
    customBaseUrl.authUrl
    );

    if(res?.status) {
        hideLoader();
        // setAccountList(res?.data)
        handleSubmit();
        console.log('gggg', res);
    } else {
        hideLoader();
        swal(res?.message)
    }
  };



  const handleSubmit = async () => {
    setLoading(true);
    
    const res = await httpPost(
      "/api/v1/auth/validate-user",
      {},
      customBaseUrl.authUrl
    );
    console.log(res);
    if (res?.status) {
    
      // setPasswordAge(res.data.passwordAge);
      /* if (passwordAge > 90){
        swal("Wrong", "Please reset password", "error");
        setShowPasswordreset(true);
        return;
      } */
      if (!res.data.pinCreated) {
        localStorage.setItem("firstLogin", true);
        // setShowPin(true);
      }
      httpPost(
        '/api/v1/history/save',
        {
          city: location?.userLocation?.city,
          country: location?.userLocation?.country_name,
          device: location?.device,
          id: 0,
          ip: location?.userLocation?.IPv4,
          province: location?.userLocation?.state,
          userId: res?.data?.id,
        },
        customBaseUrl.authUrl
      );
      if (sharedPost) {
        history.push(`/post/${sharedPostId}`);
      } else {
        // history.push('/products');
        console.log("decodedTokenhiiiii");
        const token =localStorage.getItem('token');
        const decodedToken = decodeToken(token);
        const expDate = new Date(decodedToken?.exp * 1000);
        const newDate = new Date();
        
        localStorage.setItem('userId', res?.data?.userId);
        const isPinCreated = localStorage.getItem('isPinCreated');

        console.log('userId', res?.data?.userId);
        // if (expDate > newDate) {
          const encoded = encrypt(token);
          if (res.data?.profileSubscription?.corporate) {
            window.location.href = `${envConfig.corporateAppUrl}/login?from=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.id}&pinCreated=${isPinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;

            //  window.location.href = `http://localhost:3000/login?from=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.id}&pinCreated=${isPinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;
          } else {
            window.location.href = `${envConfig.personalAppUrl}/login?from=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.id}&pinCreated=${isPinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;

            //  window.location.href = `http://localhost:3000/login?from=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.id}&pinCreated=${isPinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;
          }
        // } else {
          // setShowPasswordreset(true);
          setLoading(false);
        // }
      }
    } else {
      setLoading(false);
      swal(
        "Oops!",
        res?.message ??
        "error"
      )
    }
  };
  useEffect(() => {
    getAccounts()
  }, []);
  useEffect(() => {
    getLocationInfo();
    return () => {
      setLocation({});
    };
  }, []);
  return (
    <div id='forgot-password-modal'>
      <Modal
        isOpen={showModal}
        toggle={() => hideModal(false)}
        centered
        size='md'
      >
        {isLoading?<>
        </>:
        <div
          id='forgot-password-modal'
          className='modal-body-rs col-sm-12 col-md-10'
          style={{ marginTop: '30px' }}
        >
          <div className='header-sec-modal'>
            <input
              type='image'
              onClick={() => hideModal(false)}
              className='header-img1'
              src='./assets/image/back.png'
              alt=''
              style={{ opacity: 0 }}
            />
            <img
              className='header-sec-logo'
              src={Logo}
              alt=''
              style={{ height: '35px' }}
            />
            <input
              type='image'
              onClick={() => hideModal(false)}
              className='header-img1'
              src='./assets/image/x.png'
              alt=''
              style={{ opacity: 0 }}
            />
          </div>

          <div className='waya-modal-body'>
            <h1 className='modal-header-data'>Select Account</h1>
            {
                accountList?.map(e=> 
                    <div
                     style={{ display:'flex', borderWidth: 1, width: '90%', textAlign: 'center', borderRadius: 10, borderColor: 'gray', flexDirection: 'row'}} className='btn row mt-2'
                     onClick={()=> setDefultAccount(e)}
                     > 
                       
                        <div style={{marginTop: 0, borderRadius: 50, width: 20, textAlign: 'center'}}>
                        <UserOutlined style={{ fontSize: '150%'}}/>
                        </div>
                        <div style={{marginBottom: 15, width:'70%', justifyContent:'center', alignContent:'center'}}>
                            {`${e?.profile?.otherDetails?.organisationName}`} 
                        </div>
                    </div>
                    )
            }
            <div className='inputbox-with-one-input'>
              
            </div>
          </div>
        </div>}
      </Modal>
      {/* {showVerifyOtp ? (
        <VerifyOtp
          center
          showModal={showVerifyOtp}
          hideModal={setShowVerifyOtp}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Verify Your Account"
          description="Please input the OTP sent to your email address or phone number"
          //isResendOtp
          buttonLabel="Verify"
          handleSubmit={handleSubmit}
          loading={loading}
          numInputs={6}
          //resendOtp={setShowResendOtp}
        />
      ) : (
        ""
      )} */}
      {/* {showVerifyAccount ? (
        <VerifyOtp
          center
          showModal={showVerifyAccount}
          hideModal={setShowVerifyAccount}
          otp={otp}
          setOtp={setOtp}
          separator=""
          title="Verify Your Account"
          description="Please input the OTP sent to your email address or phone number"
          //isResendOtp
          buttonLabel="Verify"
          handleSubmit={completeSignup}
          loading={loading}
          numInputs={6}
          //resendOtp={setShowResendOtp}
        />
      ) : (
        ""
      )} */}
      {/* {showResetPassword ? (
        <ResetPassword
          center="true"
          showModal={showResetPassword}
          hideModal={setShowResetPassword}
        />
      ) : showPasswordReset ? (
        <ResetPasswordModad
          center="true"
          showModal={showPasswordReset}
          hideModal={setShowPasswordreset}
          setForgotPassord={setShowForgotPasswordModal}
          reset={setCheckReset}
        />
      ) : (
        ""
      )}
      {showResendOtp ? (
        <ResendOtp
          center="true"
          showModal={showResendOtp}
          hideModal={setShowResendOtp}
          title="Resend OTP for Verification"
          description="Please input the email or mobile number"
          handleSubmit={resendOtp}
          loading={loading}
        />
      ) : (
        ""
      )} */}
    </div>
  );
};

SelectAccount.defaultProps = {
  setShowLoginModal: false,
  setShowSignupModal: false,
};

SelectAccount.propTypes = {
  hideModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default SelectAccount;
